import Homepage from '../Container/Homepage/Homepage';

import ErrorPage from '../Container/ErrorPage/ErrorPage';
import ProductPage from '../Container/ProductPage/ProductPage';
import CartPage from '../Container/CartPage/CartPage';
import CheckoutPage from '../Container/CheckoutPage/CheckoutPage';
import OrderPage from '../Container/OrderPage/OrderPage';
import OrderDetailPage from '../Container/OrderDetailPage/OrderDetailPage';
import PaymentConfirmationPage from '../Container/PaymentConfirmationPage/PaymentConfirmationPage';

import LoginPage from '../Container/LoginPage/LoginPage';
import ProfilePage from '../Container/ProfilePage/ProfilePage';

import TermsPage from '../Container/TermsPage/TermsPage';
import PrivacyPage from '../Container/PrivacyPage/PrivacyPage';
import ReturnPolicyPage from '../Container/ReturnPolicyPage/ReturnPolicyPage';
import LinktreePage from '../Container/LinktreePage/LinktreePage';
import AboutUsPage from '../Container/AboutUsPage/AboutUsPage';
import Faqpage from '../Container/Faqpage/Faqpage';
import PaymentSuccessPage from '../Container/PaymentSuccessPage/PaymentSuccessPage';

const Routes = () => {
    return [
        {
            path: "/",
            name: "Home",
            visibility: true,
            showNav: true,
            showFooter: true,
            component: Homepage
        },
        {
            path: "/product/:code",
            name: "Product Detail",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: ProductPage
        },
        {
            path: "/cart",
            name: "Shopping Cart",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: CartPage
        },
        // {
        //     path: "/checkout",
        //     name: "Checkout Page",
        //     visibility: false,
        //     showNav: true,
        //     showFooter: true,
        //     component: CheckoutPage
        // },
        {
            path: "/order/:transactionCode",
            name: "Order Detail",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: OrderDetailPage,
        },
        {
            path: "/order",
            name: "Order",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: OrderPage,
        },
        {
            path: "/login",
            name: "Login",
            visibility: false,
            showNav: false,
            showFooter: true,
            component: LoginPage,
        },
        {
            path: "/profile/*",
            name: "Customer Profile",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: ProfilePage,
        },
        // {
        //     path: "/payment-confirmation",
        //     name: "Payment Confirmation",
        //     visibility: false,
        //     showNav: true,
        //     showFooter: true,
        //     component: PaymentConfirmationPage,
        // },
        {
            path: "/payment-success",
            name: "Payment Success",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: PaymentSuccessPage,
        },
        {
            path: "/terms-and-condition",
            name: "Terms & Conditions",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: TermsPage,
        },
        {
            path: "/privacy-policy",
            name: "Privacy Policy",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: PrivacyPage,
        },
        {
            path: "/return-policy",
            name: "Return Policy",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: ReturnPolicyPage,
        },
        // {
        //     path: "/links",
        //     name: "Linktree",
        //     visibility: false,
        //     showNav: false,
        //     showFooter: false,
        //     component: LinktreePage,
        // },
        // {
        //     path: "/about-us",
        //     name: "About Us",
        //     visibility: false,
        //     showNav: true,
        //     showFooter: true,
        //     component: AboutUsPage,
        // },
        // {
        //     path: "/faq",
        //     name: "FAQ",
        //     visibility: false,
        //     showNav: true,
        //     showFooter: true,
        //     component: Faqpage,
        // },
        {
            path: "*",
            name: "Default",
            visibility: false,
            component: ErrorPage
        }
    ];
}

export default Routes;