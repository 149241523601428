import Init from '../Init/Init';
import api from './API';

import UtilityFunction from '../Util/Util';

const { responseFormat } = UtilityFunction;

const basePath = 'testimony';
const pathList = {
    list: `/`,
};

const dummy = [
    {
        testimonyImage: `${ Init.config.testimonyDummyFolder }tanatap.png`,
        testimonySender: `John, Owner`,
        testimony: `Order packaging di Yoboy Cup sangat membantu branding coffee shop kami dan kecepatan produksi serta pelayanan yang sangat baik. Terima Kasih Yoboy Cup!`
    },
    {
        testimonyImage: `${ Init.config.testimonyDummyFolder }milk-life.png`,
        testimonySender: `MilkLife`,
        testimony: `Terima kasih Yoboy Cup sudah membantu memberikan kualitas yang baik untuk custom packaging full color saat event PRJ 2024, sangat memuaskan!`
    },
    {
        testimonyImage: `${ Init.config.testimonyDummyFolder }kopitagram.png`,
        testimonySender: `Kopitagram`,
        testimony: `Kualitas packaging yang sangat baik dan diberikan free content di Instagramnya juga. Pelayanan dan komunikasi cukup baik, Thank you Yoboy Cup!`
    },
];

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const testimonyList = async () => {
    try {
        // const apiPath = pathList.category;
        // const category = await api.getCall(apiPath);

        // return category;
        return responseFormat(0, "Successfully get testimony list!", dummy);
    } catch(e) {
        console.log(e.toString());
        return responseFormat(-1, "Failed to get testimony list!", []);
    }
};

const testimonyAPI = {
    testimonyList
}

export default testimonyAPI;