import api from './API';
import Cookie from 'universal-cookie';
import UtilityFunction from '../Util/Util';

const cookies = new Cookie();
const { responseFormat } = UtilityFunction;

const basePath = 'transaction';
const pathList = {
    list: `/`,
    order: `/order`,
    orderDetail: `/:param`,
    submitReceipt: `/submit-receipt`,
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const getOrders = async (transactionStatus, start = 0, limit = 10) => {
    const authToken = cookies.get('authToken');
    
    if(authToken === null || !authToken) return responseFormat(-1, 'Not Authenticated');

    try {
        const request = {};

        if(transactionStatus !== null) request.transactionStatus = transactionStatus;
        if(start !== null) request.start = start;
        if(limit !== null) request.limit = limit;

        const apiPath = pathList.list;
        const response = await api.getCall(apiPath, null, request, {
            "Authorization": authToken
        });

        return response;
    } catch(e) {
        console.log(e.toString());
    }
}

const order = async (requestData) => {
    try {
        const authToken = cookies.get('authToken') ? cookies.get('authToken') : "";
        const apiPath = pathList.order;

        // Products
        requestData.transactionItems = Object.values(requestData.transactionItems);

        // for(let index in transactionItems) {
        //     let item = transactionItems[index];

        //     item.price = parseFloat(item.price);

        //     transactionItems[index] = item;
        // }

        // requestData = {
        //     ...requestData,
        //     transactionItems
        // };

        console.log(requestData);

        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': authToken
        };
        const response = await api.postCall(apiPath, null, requestData, headers);

        console.log(response);

        return response;
    } catch(e) {
        console.log(e.toString());
    }
};

const getOrderDetail = async (transactionCode) => {
    try {
        const apiPath = pathList.orderDetail;

        const response = await api.getCall(apiPath, transactionCode);

        return response;
    } catch(e) {
        console.log(e.toString());
    }
};

const submitReceipt = async (requestData) => {
    try {
        const apiPath = pathList.submitReceipt;
        
        const response = await api.postCall(apiPath, null, requestData, {
            'Content-Type': 'multipart/form-data'
        });

        return response;
    } catch(e) {
        console.log(e.toString());
    }
}

const transactionAPI = {
    order,
    getOrders,
    getOrderDetail,
    submitReceipt
};

export default transactionAPI;