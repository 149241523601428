import Init from '../Init/Init';
import api from './API';

import UtilityFunction from '../Util/Util';

const { responseFormat } = UtilityFunction;

const basePath = 'instruction';
const pathList = {
    list: `/`,
};

const dummy = [
    "Pilih Variasi Kemasan sesuai kebutuhan.",
    "Pilih ukuran yang diinginkan.",
    // "Silahkan attach designmu ke dalam website. (Dalam bentuk PDF)",
    "Unggah (upload) desainmu ke dalam website. Kami merekomendasikan file dalam format PDF supaya resolusinya tinggi.",
    // "Setelah itu, lakukan pembayaran dan kami akan mengirimkan invoice Online.",
    "Masukkan nomor WhatsApp aktif. Tim kami akan menghubungi kamu nantinya untuk mengkonfirmasi besaran dan letak konten desain.",
    // "Harap masukkan nomor WA aktif. Nanti akan dihubungi oleh tim operasional Yoboy Cup untuk konfirmasi posisi (besaran dan letak konten design) dan warna saat naik cetak."
    "Lakukan pembayaran dengan pilihan metode yang tersedia."
];

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const instructionList = async () => {
    try {
        // const apiPath = pathList.category;
        // const category = await api.getCall(apiPath);

        // return category;
        return responseFormat(0, "Successfully get instruction list!", dummy);
    } catch(e) {
        console.log(e.toString());
        return responseFormat(-1, "Failed to get instruction list!", []);
    }
};

const instructionAPI = {
    instructionList
}

export default instructionAPI;