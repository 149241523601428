import React from 'react';

import './SecondaryButton.scss';

const SecondaryButton = (props) => {
    const clickButton = () => {
        if( !props.disabled ) {
            props.onClick();
        }
    }

    return (
        <button className={`btn-custom-secondary ${props.size} ${props.disabled ? 'disabled' : ''} ${props.customClass}`} onClick={() => clickButton()}>
            {props.children}
        </button>
    );
}

SecondaryButton.defaultProps = {
    disabled: false,
    customClass: '',
    onClick: () => {
        //
    },
    size: 'md'
}

export default SecondaryButton;