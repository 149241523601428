import Init from '../Init/Init';
import { isMobile } from 'react-device-detect';

const ContactInfo = () => {
    const { iconFolder, imageFolder } = Init.config;

    const contactData = {
        'maps'   : {
            link: '',
            image: 'gmaps/yoboycup.png'
        },
        'address': "",
        'phoneno': '6285777592187',
        'email': 'marketingyoboycup@gmail.com',
        'connect': [
            // {
            //     name: "YoboyCup Sneakers",
            //     link: '',
            //     show: true,
            //     faIcon: ['fab', 'whatsapp']
            // },
            {
                name: "@yoboycup",
                link: "https://www.instagram.com/yoboycup/",
                show: true,
                faIcon: ['fab', 'instagram']
            },
            {
                name: "@yoboycup",
                link: "https://www.tiktok.com/@yoboycup.official",
                show: true,
                faIcon: ['fab', 'tiktok']
            },
            // {
            //     name: "YoboyCup Facebook",
            //     link: "https://www.facebook.com/yoboycup/",
            //     show: true,
            //     faIcon: ['fab', 'facebook']
            // },
            // {
            //     name: "YoboyCup Sneakers",
            //     link: "https://www.tokopedia.com/rednavisneakers?source=universe&st=product",
            //     show: true,
            //     // faIcon: ['fab', 'tokopedia'],
            //     customIcon: `${iconFolder}tokopedia-icon.svg`
            // },
            // {
            //     name: "YoboyCup Sneakers & Apparel",
            //     link: "https://shopee.co.id/rednavisneakers",
            //     show: true,
            //     // faIcon: ['fab', 'shopee'],
            //     customIcon: `${iconFolder}shopee-icon.svg`
            // },
        ],
        'tagline': 'Your one-stop streetwear destination',
        'bankAccount': {
            "bank": "BCA",
            'bankAccountNo': '6470722221',
            'bankAccountName': 'YoboyCup',
            'bankAccountQr': `${imageFolder}qris.jpg` 
        }
    };

    const waLink = isMobile ? `https://wa.me/${contactData.phoneno}/?[queryParam]` : `https://web.whatsapp.com/send?phone=${contactData.phoneno}&[queryParam]`;

    // contactData.connect[0].link = waLink.replace(
    //     '[queryParam]', 
    //     `text=Halo Yoboycup, saya ingin menanyakan produk`
    // );
    
    // contactData.connect[1].link = waLink.replace(
    //     '[queryParam]', 
    //     `text=Halo Yoboycup, saya ingin menanyakan produk`
    // );

    return contactData;
}

export default ContactInfo;