import UtilityFunction from "../Util/Util";

const { buildWaLink } = UtilityFunction;

const ReturnPolicy = () => {
    return {
        header: {
            title: "Return Policy",
            description: [
                `Return and Refund Policy merupakan penjelasan khusus dari Syarat dan Ketentuan order online melalui website yoboycup.com seputar pengembalian produk dan dana oleh Pihak Pengguna kepada Pihak YOBOY CUP.`,
                // `Apabila Anda merasa tidak puas atau terdapat kesalahan custom dengan packaging yang Anda terima, Anda dapat mengembalikan atau menukarkan produk yang Anda terima.`
            ]
        },
        list: [
            {
                // title: "",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            `Apabila Anda merasa tidak puas atau terdapat kesalahan custom dengan packaging yang Anda terima, Anda dapat mengembalikan atau menukarkan produk yang Anda terima.`,
                            `Namun, sebelum mengajukan pengembalian atau penukaran produk, mohon membaca dengan seksama syarat dan ketentuan berikut:`
                        ]
                    },
                    {
                        type: "list",
                        content: {
                            // title: `Namun, sebelum mengajukan pengembalian atau penukaran produk, mohon membaca dengan seksama syarat dan ketentuan berikut:`,
                            list: [
                                `Pengembalian produk hanya bisa dilakukan dalam jangka waktu 1 (satu) minggu setelah produk diterima.`,
                                `Pengembalian produk sudah tidak berlaku lagi setelah melewati jangka waktu 1 (satu) minggu sesuai dengan yang sudah ditentukan.`,
                                `Nominal Dana pengembalian produk akan dikembalikan secara keseluruhan.`,
                                `Penukaran produk bisa dilakukan dalam jangka waktu kapanpun.`

                            ]
                        }
                    }
                ]
            },
            {
                title: "Bagaimana cara mengembalikan atau menukarkan produk?",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            `Silahkan menghubungi tim <a target='_blank' class='text-link' href='${ buildWaLink() }'>Customer Service YOBOY CUP</a> (setiap hari Senin-Sabtu 08.00-17.00) untuk memastikan produk yang akan Anda kembalikan atau tukarkan.`
                        ]
                    }
                ]
            }
        ]
    }
}

export default ReturnPolicy;