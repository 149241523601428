import * as actionTypes from './receiver-bank-account-types';

const INITIAL_STATE = {
    accounts: [],
    isLoaded: false
}

const receiverBankAccountReducer = (state = INITIAL_STATE, action) => {
    let { accounts, isLoaded } = state;
    
    switch(action.type) {
        case actionTypes.ACCOUNT_LIST:
            accounts = action.payload.data;
            isLoaded = accounts.length > 0;

            return {
                ...state,
                accounts,
                isLoaded
            };
        default: 
            return state;
    }
};

export default receiverBankAccountReducer;