import shoppingCartAPI from '../../Data/ShoppingCart';
import * as actionTypes from './shopping-types';

import Init from '../../Init/Init';

import Cookie from 'universal-cookie';

const cookies = new Cookie();
const { config } = Init;

const addToCart = (ownerCode, productItem) => {
    return dispatch => {
        // let authToken = cookies.get('authToken');
        let authToken = "";

        if(authToken !== undefined && authToken !== null && authToken !== '') {
            shoppingCartAPI.call.addToCart(authToken, productItem).then( (response) => {
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    ownerCode = response.data.customer_code;
                    dispatch(addToCartSuccess(ownerCode, productItem));
                }
            } );

        } else {
            dispatch(addToCartSuccess(ownerCode, productItem));
        }
    }
}

const addToCartSuccess = (ownerCode, productItem) => {
    return {
        type: actionTypes.ADD_TO_CART,
        payload: {
            ownerCode,
            productItem
        }
    }
}

const removeFromCart = (ownerCode, productId) => {
    return dispatch => {
        // let authToken = cookies.get('authToken');
        let authToken = "";

        if(authToken !== undefined && authToken !== null && authToken !== '') {
            shoppingCartAPI.call.removeCartProduct(authToken, productId).then( (response) => {
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    ownerCode = response.data.customer_code;
                    dispatch(removeFromCartSuccess(ownerCode, productId));
                }
            } );
        } else {
            dispatch(removeFromCartSuccess(ownerCode, productId));
        }
    }
}

const removeFromCartSuccess = (ownerCode, productId) => {
    return {
        type: actionTypes.REMOVE_FROM_CART,
        payload: {
            ownerCode,
            productId: productId
        }
    };
}

const adjustQty = (ownerCode, productId, value) => {
    return dispatch => {
        console.log(value);
        if(value < config.orderRule.moq) {
            dispatch(removeFromCart(ownerCode, productId));
        } else {
            dispatch(adjustQtySuccess(ownerCode, productId, value));
        }
    }    
}

const adjustQtySuccess = (ownerCode, productId, value) => {
    return {
        type: actionTypes.ADJUST_QTY,
        payload: {
            ownerCode,
            productId: productId,
            qty: value
        }
    }
}

const loadCart = (ownerCode = 'guest') => {
    return dispatch => {
        let cartData;
        // let authToken = cookies.get('authToken');
        let authToken = "";
        let loadFromLocal = false;

        if(authToken !== undefined && authToken !== null && authToken !== '') {
            shoppingCartAPI.call.loadUserCart(authToken).then( (response) => {
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    cartData = response.data.cart;
                    ownerCode = response.data.customer_code;

                    console.log(`Response API CALL: ${JSON.stringify(response)}`);
                    // console.log(authToken);
                    
                    dispatch(loadCartSuccess(ownerCode, cartData));
                } else {
                    loadFromLocal = true;
                }
            } );
        } else {
            loadFromLocal = true;
        }

        if(loadFromLocal) {
            cartData = shoppingCartAPI.controller.get(ownerCode);
            dispatch(loadCartSuccess(ownerCode, cartData));
        }
    }
}

const loadCartSuccess = (ownerCode = 'guest', cartData) => {
    return {
        type: actionTypes.LOAD_CART,
        payload: {
            ownerCode,
            cartData
        }
    }
} 

const clearCart = (ownerCode = 'guest') => {
    return {
        type: actionTypes.CLEAR_CART,
        payload: {
            ownerCode
        }
    }
}

const loadDesignAttachment = () => {
    return {
        type: actionTypes.LOAD_DESIGN_ATTACHMENT,
        payload: {}
    }
}

const saveDesignAttachment = (file) => {
    return {
        type: actionTypes.SAVE_DESIGN_ATTACHMENT,
        payload: {
            attachment: file
        }
    };
}

const shoppingCartActions = {
    addToCart,
    removeFromCart,
    adjustQty,
    loadCart,
    clearCart,

    loadDesignAttachment,
    saveDesignAttachment,
}

export default shoppingCartActions;