import React, {Component, Fragment} from 'react';
import './ReturnPolicyPage.scss';

import ReturnPolicy from '../../Data/ReturnPolicy';

const Paragraph = (props) => {
    return (
        props.data.map( (item, index) => {
            return (
                <p key={index} className="item-paragraph" dangerouslySetInnerHTML={{ __html: item }}>           
                </p>
            );
        } )
    );
}

const List = (props) => {
    return (
        <Fragment>
            <p className="item-lists-title">
                {props.data.title}
            </p>
            <ol className='item-lists'>
                {
                    props.data.list.map( (item, index) => {
                        return(
                            typeof item === 'object' ? (
                                item.map( (v, i) => {
                                    switch(v.type) {
                                        case 'paragraph':
                                            return (
                                                <Paragraph key={i} data={v.content} />
                                            );
                                        case 'list':
                                            return (
                                                <List key={i} data={v.content} />
                                            );
                                        default:
                                            return '';
                                    }
                                } )
                            ) : (
                                <li key={index} dangerouslySetInnerHTML={{__html: item}}></li>
                            )
                        );
                    })
                }
            </ol>
        </Fragment>
    );
}
class ReturnPolicyPage extends Component {
    constructor(props) {
        super(props);
        
        const returnPolicy = ReturnPolicy();
        
        this.state = {
            ...returnPolicy
        }
    }

    componentDidMount() {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        return (
            <div className="return-policy">
                <div className="return-policy-intro">
                    <h1> { this.state.header.title } </h1>
                    {
                        this.state.header.description.map( (item, index) => {
                            return (
                                <p key={index} dangerouslySetInnerHTML={{ __html: item }}>
                                </p>
                            );
                        })
                    }
                </div>
                <div className="return-policy-content">
                    <ol>
                        {
                            this.state.list.map( (item, index) => {
                                return(
                                    <div key={index} className="return-policy-content-item">
                                        {item.title && <span> {item.title} </span>}

                                        {
                                            item.description.map( (v, i) => {
                                                switch(v.type) {
                                                    case 'paragraph':
                                                        return (
                                                            <Paragraph key={i} data={v.content} />
                                                        );
                                                    case 'list':
                                                        return (
                                                            <List key={i} data={v.content} />
                                                        );
                                                    default:
                                                        return '';
                                                }
                                            } )
                                        }
                                    </div>
                                );
                            } )
                        }
                    </ol>
                </div>
            </div>
        );
    }
}

export default ReturnPolicyPage;
