import React, { 
    // useState, 
    useRef 
} from "react";
import './Counter.scss';

const Counter = (props) => {
    const { customClass, size, disabled, baseValue, onModify, minValue, maxValue, useMultiColor = 0 } = props;
    // const [baseQuantity, setBaseQuantity] = useState(baseValue);
    const quantity = useRef(baseValue);

    const toggle = {
        plus: () => {
            if(!disabled) {
                let currentQuantity = parseInt(quantity.current.value);
                // let additionCounter = currentQuantity >= 1000 ? 1000 : 250;
                let additionCounter = 250;

                // quantity.current.value = currentQuantity += (currentQuantity < maxValue ? additionCounter : 0);
                quantity.current.value = currentQuantity += additionCounter;
                
                onModify(currentQuantity); 
            }
        },
        minus: () => {
            if(!disabled) {
                let currentQuantity = parseInt(quantity.current.value);
                // let deductionCounter = currentQuantity > 1000 ? 1000 : 250;
                let deductionCounter = 250;

                quantity.current.value = currentQuantity -= (currentQuantity > minValue ? deductionCounter : 0);

                onModify(currentQuantity);
            }
        }
    }

    return (
        <div className={`textbox-container ${customClass} ${size}`}>
            <button className={`btn-toggle btn-minus ${disabled ? 'btn-disabled': ''}`} onClick={() => toggle.minus()}> - </button>
            <input type="text" ref={quantity} value={baseValue} className="quantity-input" readOnly />
            <button className={`btn-toggle btn-plus ${disabled ? 'btn-disabled': ''}`} onClick={() => toggle.plus()}> + </button>
        </div>
    );
}

Counter.defaultProps = {
    customClass: '',
    baseValue: 0,
    minValue: 0,
    maxValue: 1000,
    size: '',
    disabled: false,
    onModify: () => {
        //
    }
}

export default Counter;