import React, {Component} from 'react';

import './LoginPage.scss';

import Init from '../../Init/Init';
import UtilityFunction from '../../Util/Util';
import SecondaryButton from '../../Component/Utilities/SecondaryButton/SecondaryButton';

import NavigationContext from '../../Context/NavigationContext';
import LoginForm from '../../Component/PagesComponent/LoginPage/LoginForm/LoginForm';
import RegisterForm from '../../Component/PagesComponent/LoginPage/RegisterForm/RegisterForm';
import OTPForm from '../../Component/PagesComponent/LoginPage/OTPForm/OTPForm';

const { getGoogleOauthURL } = UtilityFunction;

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openLogin: true,
            customerCode: null
        };

        this.config = {
            googleIcon: Init.config.icons.google,
            facebookIcon: Init.config.icons.facebook
        }
    }

    static contextType = NavigationContext;

    componentDidMount() {
        window.addEventListener('resize', function(event){});
        
    }

    componentDidUpdate() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    openGoogleOauth() {
        window.location.href = getGoogleOauthURL();
    }

    setLoginOrRegister(toggle) {
        this.setState({
            ...this.state,
            openLogin: toggle
        });
    }

    onSuccess(data) {
        this.setState({
            ...this.state,
            customerCode: data.customerCode
        });
    }

    render() {
        const loginImage = Init.config.login.loginImage;
        const loginVideo = Init.config.login.loginVideo;

        return (
            <div className="login-page">
                <div className="login-image">
                    {/* <img src={ loginImage } alt="" /> */}
                    <video src={loginVideo} autoPlay={true} muted={true} loop={true}></video>
                </div>
                <div className="login-container">
                    <h1 className="login-page-title">
                        Selamat Datang di YOBOY CUP!
                    </h1>
                    {
                        this.state.customerCode ? (
                            <OTPForm customerCode={this.state.customerCode} />
                        ) : (
                            <>
                                <div className="login-select-menu">
                                    <button className={`login-select-menu-item ${ this.state.openLogin ? 'active' : '' }`} onClick={() => this.setLoginOrRegister(true)}> Login </button>
                                    <button className={`login-select-menu-item ${ this.state.openLogin ? '' : 'active' }`} onClick={() => this.setLoginOrRegister(false)}> Register </button>
                                </div>
                                {
                                    this.state.openLogin ? (
                                        <LoginForm onSuccess={ (data) => this.onSuccess(data) } />
                                    ) : (
                                        <RegisterForm onSuccess={ (data) => this.onSuccess(data) } />
                                    )
                                }
                                <span className="login-form-text">
                                    OR
                                </span>
                                <SecondaryButton size="sm" customClass='login-item login-btn' onClick={() => this.openGoogleOauth()}>
                                    <img src={this.config.googleIcon} alt="Google" />
                                    Sign In with Google
                                </SecondaryButton>
                            </>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default LoginPage;