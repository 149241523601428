import React, { useState, useEffect } from 'react';
import PaymentForm from '../../Component/PagesComponent/OrderDetailPage/PaymentForm/PaymentForm';
import CustomToast from '../../Component/Utilities/Toast/Toast';
import Init from '../../Init/Init';

import { Link, useLocation } from 'react-router-dom';

import UtilityFunction from '../../Util/Util';

import './PaymentSuccessPage.scss';
import transactionAPI from '../../Data/Transaction';

const { numberFormatting, getFormattedDatetime } = UtilityFunction;

const PaymentSuccessPage = (props) => {
    const [ isSuccess, setIsSuccess ] = useState(false);
    const { FontAwesomeIcon } = Init;
    const location = useLocation();

    const [ data, setData ] = useState({});

    const parseQueryParams = (query) => {
        return query.split("=")[1];
    }

    useEffect( () => {
        console.log(location);

        if(location.search !== '') {
            ( async () => {
                const transactionCode = parseQueryParams(location.search);

                const response = await transactionAPI.getOrderDetail(transactionCode);
                
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    setData({
                        ...response.data
                    });
                }
            } )();
        }
    }, []);

    return (
        <>
            <div className="payment-success-result-page">
                {/* 
                    1. Result in a shape of ticket
                    2. Back to homepage button
                */}
                <div 
                    // style={{
                    //     backgroundImage: `url(${ Init.config.background.paymentResult })`,
                    //     backgroundSize: 'cover',
                    //     backgroundRepeat: 'no-repeat',
                    //     backgroundAttachment: 'fixed'
                    // }}
                    className="payment-result-ticket">
                    <img src={`${ Init.config.background.paymentResult }`} alt="" />

                    <div className="payment-result-ticket-info-container">
                        <div className="payment-result-ticket-info">
                            <h1 className="payment-result-ticket-info-title">
                                Your Payment is under verification
                            </h1>
                            <p className="payment-result-ticket-info-description">
                                Our team will contact you via WhatsApp for further information
                            </p>
                            <hr />
                            <div className="payment-result-ticket-info-price">
                                <span className="price-label">
                                    Total Payment
                                </span>
                                <span className="price-value">
                                    { numberFormatting(data.total) }
                                </span>
                            </div>
                            <div className="payment-result-ticket-info-detail">
                                {/* Four blocks with fixed width */}
                                <div className="payment-result-ticket-info-detail-block">
                                    <span className="detail-label">
                                        Ref Number
                                    </span>
                                    <span className="detail-value">
                                        { data.transaction_code }
                                    </span>
                                </div>
                                <div className="payment-result-ticket-info-detail-block">
                                    <span className="detail-label">
                                        Payment Time
                                    </span>
                                    <span className="detail-value">
                                        { getFormattedDatetime(data.created_at, "d M Y, H:i") }
                                    </span>
                                </div>
                                <div className="payment-result-ticket-info-detail-block">
                                    <span className="detail-label">
                                        Payment Method
                                    </span>
                                    <span className="detail-value">
                                        { data.payment_link ? "Xendit" : "Direct Transfer" }
                                    </span>
                                </div>
                                <div className="payment-result-ticket-info-detail-block">
                                    <span className="detail-label">
                                        Sender Name
                                    </span>
                                    <span className="detail-value">
                                        { data.customer_name }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <Link className='btn-back-home' to='/'>
                    <FontAwesomeIcon icon={['fas', 'arrow-left']} /> Back to Homepage
                </Link>
            </div>
        </>
    );
}

export default PaymentSuccessPage;