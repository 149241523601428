import bankAccountAPI from '../../Data/ReceiverBankAccount';
import * as actionTypes from './receiver-bank-account-types';

const getAccounts = () => {
    return async dispatch => {
        const response = await bankAccountAPI.accountList();
    
        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            dispatch(getAccountsSuccess(response.data)); 
        }
    }
}

const getAccountsSuccess = (data) => {
    return {
        type: actionTypes.ACCOUNT_LIST,
        payload: {
            data
        }
    };
}

const receiverBankAccountActions = {
    getAccounts
}

export default receiverBankAccountActions;