import React from 'react';

import PrimaryButton from '../PrimaryButton/PrimaryButton';
import LoadingPage from '../LoadingPage/LoadingPage';

import './EmptyCart.scss';

function EmptyCart({ isLoading }) {
    const backToHomepage = () => {
        window.location.href = '/';
    }
    
    return (
        <div className="shopping-cart-empty">
            {
                !isLoading ? (
                    <>
                        <span className='empty-cart-text'>
                            Keranjangmu masih kosong nih~
                        </span>
                        <PrimaryButton size="sm" onClick={ () => backToHomepage() }>
                            Kembali ke halaman utama
                        </PrimaryButton>
                    </>
                ) : (
                    <LoadingPage />
                )
            }
        </div>
    );
}

export default EmptyCart;