import * as actionTypes from './addon-types';

const INITIAL_STATE = {
    addonsList: [],
    isLoaded: false
}

const addonsReducer = (state = INITIAL_STATE, action) => {
    let { addonsList, isLoaded } = state;
    
    switch(action.type) {
        case actionTypes.ADDON_LIST:
            addonsList = action.payload.data;
            isLoaded = addonsList.length > 0;

            return {
                ...state,
                addonsList,
                isLoaded
            };
        default: 
            return state;
    }
};

export default addonsReducer;