import React from "react";
import './PaymentForm.scss';

const PaymentForm = (props) => {
    const {
        onChange
    } = props;
    
    return (
        <div className="payment-form">
            <h2 className="payment-form-title">
                Payment Methods
            </h2>

            <div className="payment-form-container">
                <div className="payment-form-option">
                    <label htmlFor="xendit">
                        <input type="radio" id="xendit" name="payment" value={ 1 } onClick={ () => onChange(1) } /> Xendit
                    </label>
                    <span className="description">
                        Pay via XENDIT Payment Gateway
                    </span>
                </div>
                {/* <div className="payment-form-option">
                    <label htmlFor="direct-transfer">
                        <input type="radio" id="direct-transfer" name="payment" value={ 0 } onClick={ () => onChange(0) } /> Direct Bank Transfer
                    </label>
                    <span className="description">
                        Make payment directly through bank account.
                    </span>
                </div> */}
            </div>
        </div>
    );
}

export default PaymentForm;