import React, { useState, useRef } from 'react';
import FormInput from '../../Utilities/FormInput/FormInput';

import { Row } from 'react-bootstrap';

import './AddressForm.scss';
import { useEffect } from 'react';
import regionAPI from '../../../Data/Region';

const AddressForm = (props) => {
    const { title, className, onModify, contactFormData, shippingFormData } = props;

    const [ regions, setRegions ] = useState({
        province: [],
        regency: [],
        district: [],
        village: []
    });

    const buttonContainer = props.children;

    useEffect( () => {

        ( async () => {
            let provinces = [];

            const response = await getRegions('province');

            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                for(let index in response.data) {
                    let item = response.data[index];

                    provinces.push({
                        value: item.code,
                        label: item.name
                    });
                }
                
                setRegions({
                    province: [
                        {'value': 0, 'label': 'Pilih Provinsi'},
                        ...provinces
                    ],
                    regency: [
                        {'value': 0, 'label': 'Pilih Kota/Kabupaten'}
                    ],
                    district: [
                        {'value': 0, 'label': 'Pilih Kecamatan'}
                    ],
                    village: [
                        {'value': 0, 'label': 'Pilih Kelurahan'}
                    ]
                });
            }
        } )();
    }, []);

    useEffect(() => {
        if(regions.province.length > 0) {
            if(shippingFormData.hasOwnProperty('province') && shippingFormData.province.hasOwnProperty('value') && shippingFormData.province.value !== 0) {
                formUpdate('province', shippingFormData.province.value);
            }

            if(shippingFormData.hasOwnProperty('regency') && shippingFormData.regency.hasOwnProperty('value') && shippingFormData.regency.value !== 0) {
                formUpdate('regency', shippingFormData.regency.value);
            }

            if(shippingFormData.hasOwnProperty('district') && shippingFormData.district.hasOwnProperty('value') && shippingFormData.district.value !== 0) {
                formUpdate('district', shippingFormData.district.value);
            }
        }
    }, [regions.province.length]);

    const getRegions = async (regionType, id = '') => {
        let response;
        
        switch(regionType) {
            case 'province':
                response = await regionAPI.provinceList();
                break;
            case 'regency':
                response = await regionAPI.regencyList(id);
                break;
            case 'district':
                response = await regionAPI.districtList(id);
                break;
            case 'village':
                response = await regionAPI.villageList(id);
                break;
            default:
                response = {};
        }

        return response;
    }

    const formUpdate = (name, value) => {
        let requestName = '';
        let regionData = regions;

        switch(name) {
            case 'province':
                requestName = 'regency';
                
                regionData['regency'] = [
                    {'value': 0, 'label': 'Pilih Kota/Kabupaten'}
                ];

                regionData['district'] = [
                    {'value': 0, 'label': 'Pilih Kecamatan'}
                ];

                regionData['village'] = [
                    {'value': 0, 'label': 'Pilih Kelurahan'}
                ];

                break;
            case 'regency':
                requestName = 'district';

                regionData['district'] = [
                    {'value': 0, 'label': 'Pilih Kecamatan'}
                ];

                regionData['village'] = [
                    {'value': 0, 'label': 'Pilih Kelurahan'}
                ];

                break;
            case 'district':
                requestName = 'village';
                
                regionData['village'] = [
                    {'value': 0, 'label': 'Pilih Kelurahan'}
                ];

                break;
            default:
                break;
        }

        if(requestName !== '') {
            getRegions(requestName, value).then((response) => {
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    let data = response.data;
                    
                    for(let index in data) {
                        let item = data[index];

                        regionData[requestName].push({
                            value: item.code,
                            label: item.name
                        });
                    }

                    setRegions({
                        ...regionData
                    });
                }
            });
        }

        onModify(name, value);
    };

    return (
        <Row className={`address-form ${className}`}>
            <h3 className='col-md-12 address-form-title'>
                Contact Details
            </h3>
            {
                Object.keys(contactFormData).map( (key, index) => {
                    let item = contactFormData[key];

                    return <FormInput 
                        key={index} 
                        attribute={item}
                        options={regions[key]}
                        name={key} 
                        onChange={ (name, value) => formUpdate(name, value) } 
                    />
                } )
            }
            <h3 className='col-md-12 address-form-title'>
                Shipping Details
            </h3>
            {
                Object.keys(shippingFormData).map( (key, index) => {
                    let item = shippingFormData[key];

                    return <FormInput 
                        key={index} 
                        attribute={item}
                        options={regions[key]}
                        name={key} 
                        onChange={ (name, value) => formUpdate(name, value) } 
                    />
                } )
            }
            {
                buttonContainer
            }
        </Row>
    );
}

AddressForm.defaultProps = {
    className: '',
    onModify: (name, value) => {
        //
    },
    useTitle: true,
    contactFormData: {},
    shippingFormData: {}
}

export default AddressForm;