import React, { Component } from 'react';
import NavigationContext from '../../Context/NavigationContext';

import ShoppingCart from '../../Component/PagesComponent/ShoppingCart/ShoppingCart';
import RecommendedProducts from '../../Component/PagesComponent/RecommendedProducts/RecommendedProducts';

import './CartPage.scss';

class CartPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cartData: {}
        }
    }

    static contextType = NavigationContext;

    componentDidMount() {
        window.addEventListener('resize', () => {});
    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    render() {
        return (
            <div className='shopping-cart-page'>
                {/* 
                    3 Big Parts
                    1. Shopping Cart
                    2. Checkout Section
                    3. Recommended Products (?)
                */}
                <ShoppingCart id="section-1" />
                {/* <RecommendedProducts id="section-3" /> */}
            </div>
        );
    }
}

CartPage.propTypes = {

};

export default CartPage;