import React, { useState, useEffect, useRef, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import FormInput from '../../../Utilities/FormInput/FormInput';
import PrimaryButton from '../../../Utilities/PrimaryButton/PrimaryButton';

import NavigationContext from '../../../../Context/NavigationContext';

import './PaymentForm.scss';
import ContactInfo from '../../../../Data/Contact';
import bankAPI from '../../../../Data/Bank';
import transactionAPI from '../../../../Data/Transaction';

import UtilityFunction from '../../../../Util/Util';
import CustomToast from '../../../Utilities/Toast/Toast';

const { getFormattedDatetime, base64encode } = UtilityFunction;

function PaymentForm(props) {
    const [banks, setBanks] = useState([]);
    const [formData, setFormData] = useState({
        /* 
            1. Transaction Code (Order No.)
            2. Transfer Time (Tgl Transfer)
            3. Sender (Nama Pengirim)
            4. Account No. (No Rekening)
            5. Bank Name (Nama Bank)
            6. Proof of Transfer (Bukti Transfer)
        */
        transaction_code: {
            label: "Order No.",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            required: true,
            placeholder: 'E.g.: TX-00XXXXX'
        },
        transferred_at: {
            label: "Tanggal Transfer",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "date",
            required: true,
            placeholder: ''
        },
        bank_account_name: {
            label: "Nama Pengirim",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            required: true,
            placeholder: 'E.g.: Cahyadi'
        },
        bank_account_no: {
            label: "No. Rekening",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            required: true,
            numeric: true,
            placeholder: 'E.g.: 1234567890'
        },
        bank_code: {
            label: "Nama Bank",
            ref: useRef(0),
            value: 0,
            width: 12, // x < 12
            type: "select",
            required: true,
            options: [
                {'value': 0, 'label': 'Pilih Bank'},
            ],
        },
        proof_of_transfer: {
            label: "",
            ref: useRef(null),
            value: null,
            width: 12, // x < 12
            type: "file",
            required: true,
        }
    });
    const navigation = useContext(NavigationContext);
    const contact = ContactInfo();
    const { bankAccount } = contact;

    /**
     * Toast
     */
    const [toastContent, setToastContent] = useState({
        title: '',
        content: '',
        status: null,
        show: false
    });

    const showToast = (success = false, message) => {
        const content   = toastContent;
        content.title   = success ? 'Success' : 'Failed';
        content.content = message;
        content.status  = success;
        content.show    = true;

        setToastContent({
            ...content,
        });
    }

    const closeToast = () => {
        setToastContent({
            ...toastContent,
            show: false
        });
    };

    useEffect( () => {
        const { params } = navigation;

        if(params !== null && params.hasOwnProperty('transactionCode')) {
            formData.transaction_code.value = params.transactionCode;
            
            if(formData.transaction_code.ref !== '' && formData.transaction_code.ref.current.hasOwnProperty('value')) {
                formData.transaction_code.ref.current.value = params.transactionCode;
            }
        }
    }, []);

    useEffect(() => {
        (
            async () => {
                const response = await bankAPI.bankList();
                let bankData = [];

                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    for(let index in response.data) {
                        let item = response.data[index];

                        bankData.push({
                            value: item.bank_code,
                            label: item.bank_short_name
                        });
                    }

                    setBanks([
                        ...bankData
                    ]);
                }
            }
        )();
    }, []);

    useEffect( () => {
        formData.bank_code.options = banks;

        setFormData({
            ...formData
        });
    }, [banks]);

    const formUpdate = (name, value) => {
        // console.log({
        //     name,
        //     value
        // });
        switch(name) {
            case 'bank_account_no':
                if(value.match(/^[0-9]+$/) !== null) {
                    formData[name].hasError = false;
                    formData[name].errorMessage = '';
                } else {
                    formData[name].hasError = true;
                    formData[name].errorMessage = 'Invalid Bank Account No.';
                }

                setFormData({
                    ...formData
                });
                break;
        }
    }

    const submitForm = () => {
        // console.log(Object.keys(formData).map((name, index) => {
        //     let item = formData[name];

        //     return [name, item.ref.current.value];
        // }));
        let requestData = {};

        if(formData.transaction_code.ref.current.value !== '') {
            for(let key in formData) {
                let item = formData[key];
                let value = item.ref.current.value;
                value = (value !== undefined && value !== null) ? value : '';

                if(key === 'transferred_at') {
                    value = getFormattedDatetime(value);
                }

                // if(key === 'proof_of_transfer') {
                //     value = base64encode(value);
                // }

                requestData[key] = value;
            }

            (
                async() => {
                    const response = await transactionAPI.submitReceipt(requestData);
                    
                    if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                        showToast(true, response.message);
                        // Navigate to payment result
                    } else {
                        for(let index in response.data) {
                            let item = response.data[index];
                            let field = item.hasOwnProperty('field') ? item.field : '';
                            let message = item.hasOwnProperty('message') ? item.message : '';

                            if(field !== '' && formData.hasOwnProperty(field)) {
                                formData[field].hasError = true;
                                formData[field].errorMessage = message;
                            }
                        }

                        showToast(false, response.message);

                        setFormData({
                            ...formData
                        });
                    }

                    props.onSubmit(response.hasOwnProperty('errorCode') && response.errorCode === 0, response.message);
                }
            )();
        } else {
            formData.transaction_code.hasError = true;
            formData.transaction_code.errorMessage = 'Order no. is required!';

            setFormData({
                ...formData
            });
        }
    };

    return (
        <>
            <div className='payment-form-container'>
                <CustomToast show={toastContent.show} 
                    onClose={closeToast} 
                    title={toastContent.title} 
                    status={toastContent.status}
                    customClass="payment-confirmation-toast"
                >
                    <div className="content">
                        { toastContent.content }
                    </div>
                </CustomToast>
                <Row className={`payment-form`}>
                    {
                        Object.keys(formData).map( (key, index) => {
                            let item = formData[key];

                            return <FormInput 
                                        key={index} 
                                        attribute={item}
                                        options={item.hasOwnProperty('options') ? item.options : []}
                                        name={key} 
                                        onChange={ (name, value) => formUpdate(name, value) } 
                                        customClass={`payment-confirmation-form-input`}
                                    />
                        } )
                    }
                    <Col lg={12} className='btn-payment-confirm-container'>
                        <PrimaryButton size="md" customClass="btn-payment-confirm" onClick={() => submitForm()}>
                            Submit
                        </PrimaryButton>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default PaymentForm;