import React, { useEffect, useState } from 'react';

import Init from '../../../../Init/Init';
import './Header.scss';

import LoadingPage from '../../../Utilities/LoadingPage/LoadingPage';
import PrimaryButton from '../../../Utilities/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../../Utilities/SecondaryButton/SecondaryButton';
import PhotoCarousel from '../../ProductPage/PhotoCarousel/PhotoCarousel';

import useAnalyticsEventTracker from '../../../../GoogleAnalytics/useAnalyticsEventTracker';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Header';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const Header = (props) => {
    const {
        firstImage,
        secondImage,
        thirdImage,
        fourthImage,
        fifthImage,
        sixthImage,
        seventhImage
    } = Init.config.header;

    // Call API
    useEffect(() => {
        console.log("Render header!");
    }, []);

    const order = () => {
        window.scrollTo({
            top: document.getElementById("packaging-catalog").offsetTop,
            behavior: 'smooth'
        });
    }

    return (
        <div id="header" className="header">
            {/* 
                1. Caps
                2. Image
            */}
            <div className="header-caption">
                <h1 className='header-caption-title'>
                    Bikin Kemasanmu #JadiLebihKeren Di <br /> Yoboy Cup!
                </h1>
                <p className="header-caption-text">
                    PT. Yoboy Oswald Imaji (YOBOY CUP) merupakan perusahaan custom kemasan cup dan box makanan. Kami menyediakan custom cup plastik, paper cup, paper bowl, dan lain-lain.
                </p>
                <p className="header-caption-text">
                    Kami melayani konsumen dengan cepat, nyaman, dan tentunya menggunakan 100% kualitas terbaik.
                </p>
                {/* <p className="header-caption-text">
                    <strong> *PEMBAYARAN BISA MENGGUNAKAN CREDIT CARD YA :) </strong>
                </p> */}
                <div className="header-caption-button-container">
                    <PrimaryButton 
                        onClick={ () => order() }
                        size={'sm'}
                        customClass={"header-caption-button"}
                    >
                        Custom Sekarang
                    </PrimaryButton>
                    <SecondaryButton 
                        onClick={ () => order() }
                        size={'sm'}
                        customClass={"header-caption-button"}
                    >
                        Lihat Produk
                    </SecondaryButton>
                </div>
                <div className="header-caption-desc">
                    {/* Brands */}
                </div>
            </div>
            <PhotoCarousel photos={[
                firstImage,
                secondImage,
                thirdImage,
                fourthImage,
                fifthImage,
                sixthImage,
                seventhImage
            ]} />
            {/* <div className="header-image">
                <img src={ headerImage } />
            </div> */}
        </div>
    );
}



export default Header;