import * as actionTypes from './shipping-types';

const INITIAL_STATE = {
    couriers: [],
    isLoaded: false
}

const shippingReducer = (state = INITIAL_STATE, action) => {
    let { couriers, isLoaded } = state;
    
    switch(action.type) {
        case actionTypes.COURIER_LIST:
            couriers = action.payload.data;
            isLoaded = couriers.length > 0;

            return {
                ...state,
                couriers,
                isLoaded
            };
        default: 
            return state;
    }
};

export default shippingReducer;