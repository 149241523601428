import React, { useEffect, useState } from 'react';

import Init from '../../../../Init/Init';
import './About.scss';

import LoadingPage from '../../../Utilities/LoadingPage/LoadingPage';
import PrimaryButton from '../../../Utilities/PrimaryButton/PrimaryButton';

import useAnalyticsEventTracker from '../../../../GoogleAnalytics/useAnalyticsEventTracker';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'About';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const About = (props) => {
    // Call API
    useEffect(() => {
        console.log("Render about!");
    }, []);

    return (
        <div className="about">
            {/* 
                1. Title
                2. Desc
            */}
            <h2 className="about-title">
                Tentang Yoboy Cup
            </h2>
            <div className="about-desc">
                <p>
                    YOBOY CUP (PT. Yoboy Oswald Imaji) berdiri sejak 13 Maret 2018. Kami melakukan pengiriman ke seluruh Indonesia, dan bisa diluar dari itu.
                </p>
                <p>
                    Kami menyadari kemasan adalah identitas sebuah produk. Kualitas kemasan akan mempengaruhi kesan pertama para pelanggan bisnis Anda. Detail yang baik akan menghasilkan kepuasan tak ternilai.
                </p>
                <p>
                    Berorientasi pada kebutuhan setiap customer adalah kultur manajemen kami. Pengalaman yang cukup di industri ini membawa kami semakin baik dari waktu ke waktu untuk menjadi mitra para pelaku industri food and beverages di Indonesia.
                </p>
                <p>
                    Saat ini kami berlokasi di Jakarta. Namun kami memiliki banyak <i>repeat order customer</i> dari seluruh Nusantara. 99% dari mereka belum pernah bertemu tatap muka dengan kami.
                </p>
                <p>                
                    Bagi kami reputasi adalah KUNCI.
                </p>
            </div>
        </div>
    );
}



export default About;