import React from "react";
import './PaymentDirectionForm.scss';
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    return {
        accounts: state.receiverBankAccount.accounts
    }
};

const PaymentDirectionForm = (props) => {
    return (
        <div className="payment-direction-form">
            <h2 className="payment-direction-form-title">
                Pilih Rekening Tujuan
            </h2>

            <div className="payment-direction-form-container">
                {
                    props.accounts.map( (item, index) => {
                        return (
                            <div className="payment-direction-form-option">
                                <label htmlFor={ `payment-recipient-${index}` }>
                                    <input type="radio" id={ `payment-recipient-${index}` } name="payment-recipient" value={ item.id } onClick={ () => props.onChange(item.id) } /> { item.bank_code } - { item.account_no }
                                </label>
                                <span className="description">
                                    A/N { item.account_name }
                                </span>
                            </div>
                        );    
                    } )
                }
                
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(PaymentDirectionForm);