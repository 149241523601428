import React from 'react';
import Init from '../../../Init/Init';
import ContactInfo from '../../../Data/Contact';

import { Link } from 'react-router-dom';

import './WhatsappButton.scss';
import UtilityFunction from '../../../Util/Util';

const { buildWaLink } = UtilityFunction;

const WhatsappButton = (props) => {
    const { FontAwesomeIcon, config } = Init;
    const whatsappIcon = config.icons.whatsapp;
    const { isFixed, customClass, show } = props;
    const info = ContactInfo();

    const waLink = buildWaLink();
    

    return (
        <a 
            target="_blank" 
            className={`btn-pre-order ${show ? '' : 'd-none'} ${isFixed ? 'is-fixed' : ''} ${customClass}`} 
            // className={`btn-pre-order ${ isFixed ? "is-fixed" : "" } ${ customClass }`}
            href={waLink}
        >
            <img className='btn-pre-order-icon' src={whatsappIcon} alt="" /> 
            <div className='btn-pre-order-text'>
                <span className="primary-text">
                    Butuh Bantuan?
                </span>
                {/* <span className="secondary-text">
                    Admin Sales 3
                </span> */}
            </div>
        </a>
    );
}

WhatsappButton.defaultProps = {
    isFixed: false,
    customClass: '',
    show: true
}

export default WhatsappButton;