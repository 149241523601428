import React, { useState, useEffect } from 'react';
import Init from '../../Init/Init';
import linktree from '../../Data/Linktree';
import { isMobile } from 'react-device-detect';

import './LinktreePage.scss';

const { config } = Init;
const { linktreeFolder, imageLogoAlt } = config;
// const data = linktree();

const LinktreePage = (props) => {
    const [ data, setData ] = useState([]);

    useEffect( () => {
        ( async () => {
            const response = await linktree();

            if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                setData(response.data);
            }
        } )();
    }, []);

    return (
        <div className="linktree-page">
            <div className="linktree-container">
                {/* 
                    1. Logo
                    2. Instagram
                    3. Tagline | Since 2019
                    4. List Linktree
                */}
                <div className="linktree-logo">
                    <img src={ `${linktreeFolder}logo.svg` } alt="" />
                </div>
                <div className="linktree-header">
                    <h1 className="linktree-title">
                        @yoboycup
                    </h1>
                </div>
                <div className="linktree-tagline">
                    One-Stop Streetwear Destination | Since 2019
                </div>
                <div className="linktree-list">
                    {
                        data.map( (item, index) => {
                            return (
                                <a href={ `${!isMobile ? item.desktop_link : item.mobile_link}` } className="linktree-item">
                                    <div className="linktree-item-icon">
                                        <img src={ `${process.env.REACT_APP_API_LINKY_STORAGE}${item.icon}` } alt="" />
                                    </div>
                                    <span className="linktree-item-caption">
                                        { item.caption }
                                    </span>
                                    <div className="linktree-item-logo">
                                        <img src={ `${imageLogoAlt}` } alt="" />
                                    </div>
                                </a>
                            )
                        } )
                    }
                </div>
            </div>
        </div>
    );
}

export default LinktreePage;