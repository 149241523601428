import React from 'react';

import './PrimaryButton.scss';

const PrimaryButton = (props) => {
    const clickButton = () => {
        if( !props.disabled ) props.onClick();
    }

    return (
        <button className={`btn-custom-primary ${props.size} ${props.disabled ? 'disabled' : ''} ${props.customClass}`} onClick={() => clickButton()}>
            {props.children}
        </button>
    );
}

PrimaryButton.defaultProps = {
    disabled: false,
    customClass: '',
    onClick: () => {
        //
    },
    size: 'md'
}

export default PrimaryButton;