import api from './API';
import Cookie from 'universal-cookie';
import UtilityFunction from '../Util/Util';

const cookies = new Cookie();
const { responseFormat } = UtilityFunction;

const basePath = 'receiver-bank-account';
const pathList = {
    list: `/`,
    get: `/:param`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const accountList = async () => {
    try {
        const apiPath = pathList.list;
        const response = await api.getCall(apiPath);

        return response;
    } catch(e) {
        console.log(e.toString());
    }
};

const accountGet = async (id) => {
    try {
        const apiPath = pathList.get;

        const response = await api.getCall(apiPath, id);

        return response;
    } catch(e) {
        console.log(e.toString());
    }
};

const bankAccountAPI = {
    accountList,
    accountGet,
};

export default bankAccountAPI;