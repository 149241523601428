import React, {Component} from 'react';

import OrderDetail from '../../Component/PagesComponent/OrderDetailPage/OrderDetail/OrderDetail';

import './OrderDetailPage.scss';
import NavigationContext from '../../Context/NavigationContext';

import useAnalyticsEventTracker from '../../GoogleAnalytics/useAnalyticsEventTracker';
import { Modal } from 'react-bootstrap';
import PaymentForm from '../../Component/PagesComponent/OrderDetailPage/PaymentForm/PaymentForm';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Order Detail';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

class OrderDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionCode: '',
            checkoutSuccess: 0,
            showConfirmationModal: false
        }
    }

    static contextType = NavigationContext;

    componentDidMount() {
        const state = this.state;
        const context = this.context;

        const { navigate, location, params } = context;
        const locationState = location.state;

        if(params.hasOwnProperty('transactionCode')) {
            state.transactionCode = params.transactionCode;
            state.checkoutSuccess = locationState !== null && locationState.hasOwnProperty('success') ? locationState.success : false;

            if(state.checkoutSuccess) {
                GaTracker({
                    action: `Checkout`,
                    label: `Checkout Success with trx code ${state.transactionCode}`
                });
            }

            this.setState(state);
        } else {
            navigate('/order', {
                replace: true,
                state: {}
            });
        }

        window.addEventListener('resize', function(event){});
    }

    componentDidUpdate() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    payNow(paymentLink) {
        if( paymentLink !== "" ) {
            window.open(paymentLink, '_blank');
        } else {
            // Open payment confirmation modal
            this.toggleConfirmationModal(true);
            // navigate(`/order/${response.data.transactionCode}`, {
            //     replace: true,
            //     state: {
            //         success: 1
            //     }
            // });
        }
    }

    toggleConfirmationModal(toggle) {
        this.setState({
            ...this.state,
            showConfirmationModal: toggle
        });
    }

    submitAttempt(success) {
        if( success ) {
            // console.log("Success!");
            this.context.navigate(`/payment-success?transactionCode=${this.state.transactionCode}`, {
                replace: true
            });
        }
    }

    render() {
        return (
            <div className='order-detail-page'>
                <Modal className='payment-confirmation-modal' show={ this.state.showConfirmationModal } onHide={ () => this.toggleConfirmationModal(false) }>
                    <Modal.Header>
                        <Modal.Title>
                            Please confirm your payment!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PaymentForm onSubmit={ (success) => this.submitAttempt(success) }/>
                    </Modal.Body>
                </Modal>
                <OrderDetail transactionCode={this.state.transactionCode} clickPayNow={(paymentLink) => this.payNow(paymentLink)} />
            </div>
        );
    }
}

export default OrderDetailPage;