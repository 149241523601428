import React, { useState, useEffect, useRef, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import FormInput from '../../../Utilities/FormInput/FormInput';
import NavigationContext from '../../../../Context/NavigationContext';

import './CustomDesignForm.scss';

import UtilityFunction from '../../../../Util/Util';

const { getFormattedDatetime, base64encode } = UtilityFunction;

function CustomDesignForm(props) {
    const navigation = useContext(NavigationContext);
    const designAttachmentRef = useRef(null);

    useEffect( () => {

    }, []);

    const formUpdate = (name, value) => {
        // Update parent value
        props.updateAttachment(value);
    }

    return (
        <>
            <div className='custom-design-form-container'>
                <h2 className="custom-design-form-title">
                    Custom Design (Max 100 MB)
                </h2>
                <Row className={`custom-design-form`}>
                    <FormInput  
                        attribute={{
                            label: "",
                            ref: designAttachmentRef,
                            value: props.attachment,
                            width: 12,
                            type: "file",
                            required: false,
                            allowedFileTypes: ['PDF', 'PSD'],
                            maxFileSize: 100 // MB
                        }}
                        options={[]}
                        name={"design_attachment"} 
                        onChange={ (name, value) => formUpdate(name, value) } 
                        customClass={`custom-design-form-input`}
                    />
                </Row>
            </div>
        </>
    );
}

export default CustomDesignForm;