import React from 'react';
import { Spinner } from 'react-bootstrap';

import './LoadingPage.scss';

function LoadingPage(props) {
    return (
        <div className='yoboycup-loading-page'>
            <Spinner animation='border' />
        </div>
    );
}

export default LoadingPage;