const controller = {
    get: () => {
        let storage = localStorage.getItem('designAttachment');
        
        if(storage === null || storage === '' || storage === '{}') {
            storage = '';
        } else {
            // storage = JSON.parse(storage);
        }

        return storage;
    },
    save: (file) => {
        try {      
            localStorage.setItem('designAttachment', file);

            return true;
        } catch(e) {
            console.error(e);
            return false;
        }
    },
    clear: () => {
        try {
            localStorage.setItem('designAttachment', '');
    
            return true;
        } catch(e) {
            console.error(e);
            return false;
        }
    }
}

const designAttachmentAPI = {
    // call,
    controller
};

export default designAttachmentAPI;