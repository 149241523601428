import { combineReducers } from 'redux';

import authReducer from './Auth/auth-reducer';
import shopReducer from './Shopping/shopping-reducer';
import brandReducer from './Brand/brand-reducer';
import groupReducer from './Group/group-reducer';
import marqueeReducer from './Marquee/marquee-reducer';
import FAQReducer from './FAQ/faq-reducer';
import addonsReducer from './Addon/addon-reducer';
import shippingReducer from './Shipping/shipping-reducer';
import receiverBankAccountReducer from './ReceiverBankAccount/receiver-bank-account-reducer';

const rootReducer = combineReducers({
    shop: shopReducer,
    auth: authReducer,
    addons: addonsReducer,
    shipping: shippingReducer,
    receiverBankAccount: receiverBankAccountReducer,
    // brand: brandReducer,
    // group: groupReducer,
    // marquee: marqueeReducer,
    // FAQ: FAQReducer
});

export default rootReducer;