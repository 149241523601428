import api from './API';

const basePath = 'shipping';
const pathList = {
    courier: '/courier'
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const courierList = async () => {
    try {
        const apiPath = pathList.courier;
        const courier = await api.getCall(apiPath);

        return courier;
    } catch(e) {
        console.log(e.toString());
    }
}

const shippingAPI = {
    courierList
}

export default shippingAPI;