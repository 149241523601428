import Init from '../Init/Init';
import api from './API';

import UtilityFunction from '../Util/Util';

const { responseFormat } = UtilityFunction;

const basePath = 'add-ons';
const pathList = {
    addonList: `/`,
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const addonsList = async () => {
    try {
        const apiPath = pathList.addonList;
        const addons = await api.getCall(apiPath);
        
        return addons;
    } catch(e) {
        console.log(e.toString());
    
        return responseFormat(-1, "Failed to get addons!");
    }
    // return responseFormat(0, "Successfully get addons", dummy);
};

const addonsAPI = {
    addonsList,
}

export default addonsAPI;