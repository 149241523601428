import React, { useEffect, useRef } from "react";
import './ShippingForm.scss';

import UtilityFunction from "../../../../Util/Util";
import { connect } from "react-redux";

const { numberFormatting } = UtilityFunction;

const mapStateToProps = (state) => {
    return {
        couriers: state.shipping.couriers
    }
};

const ShippingForm = (props) => {
    const radioRef = [
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
    ];

    const chooseShipping = (index, value) => {
        props.onChange(value);
        radioRef[index].current.checked = radioRef[index].current.value == value;
    }

    useEffect( () => {
        radioRef[0].current.checked = radioRef[0].current.value == props.chosenShipping;
        // radioRef[1].current.checked = radioRef[1].current.value == props.chosenShipping;
    }, [props.couriers]);

    return (
        <div className="shipping-form">
            <h2 className="shipping-form-title">
                Opsi Pengiriman
            </h2>

            <div className="shipping-form-container">
                {
                    props.couriers.map( (item, index) => {
                        console.log({id: item.id, chosen: props.chosenShipping});

                        return (
                            <div className="shipping-form-option">
                                <label htmlFor={ `courier-${index}` }>
                                    <input 
                                        type="radio" 
                                        id={ `courier-${index}` } 
                                        name="shipping" 
                                        value={ item.id } 
                                        onClick={ () => chooseShipping(index, item.id) } 
                                        ref={ radioRef[index] }
                                    /> { item.courier_name }
                                </label>
                                <span className={`description ${ item.price > 0 ? "" : "free" }`}>
                                    { item.price > 0 ? numberFormatting(item.price) : "FREE" }
                                </span>
                            </div>
                        );    
                    } )
                }
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(ShippingForm);