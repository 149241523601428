import React, { useState, useEffect } from 'react';
// import shoppingCartAPI from '../../../Data/ShoppingCart';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Init from '../../../Init/Init';
import UtilityFunction from '../../../Util/Util';
import CustomToast from '../../Utilities/Toast/Toast';
import ShoppingCart from '../../Utilities/ShoppingCart/ShoppingCart';

import { connect } from 'react-redux';
import { userLogout } from '../../../Redux/Auth/auth-action';

import './TopMenu.scss';
import MobileSidebar from '../MobileSidebar/MobileSidebar';

const { objectIsEmpty, buildWaLink } = UtilityFunction;

const mapStateToProps = (state) => {
    return {
        identity: state.auth.identity,
        cartCount: state.shop.cartCounter
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(userLogout())
        }
    }
}

const TopMenu = (props) => {
    const { FontAwesomeIcon, config } = Init;
    const [ show, setShow ] = useState(false);

    const { allowTransaction } = config;

    const [ customer, setCustomer ] = useState({});
    const [ cartCounter, setCartCounter ] = useState(0);
    const [ mobileScreen, setMobileScreen ] = useState(false);
    const [ showMobileSidebar, setShowMobileSidebar ] = useState(false);
    const [ menu, setMenu ] = useState([]);

    const waLink = buildWaLink();

    useEffect( () => {
        let wasLogin = customer.hasOwnProperty('id');

        if(wasLogin && objectIsEmpty(props.identity)) {
            let toast = toastContent;
            let customer_username = customer.username;

            toast.title = 'Logged Out!';
            toast.status = 1;
            toast.content.name = customer_username;
            toast.content.text = ' has been logged out!';
            toast.show = true;
            updateToast(toast);
        }

        if(props.identity !== undefined) {
            setCustomer(props.identity);

        }
    }, [props.identity]);

    useEffect( () => {
        setMobileScreen(props.mobileScreen);
    }, [props.mobileScreen]);

    useEffect( () => {
        setMenu(props.menu);
    }, [props.menu]);

    const logout = () => {
        props.logout();
    }

    useEffect( () => {
        const { cartCount } = props;

        setCartCounter(cartCount);
    }, [show, props.cartCount] );

    const openShoppingCart = () => {
        setShow(true);
    }

    const closeShoppingCart = () => {
        setShow(false);
    }
    
    const [ toastContent, setToastContent ] = useState({
        title: '',
        content: {
            name: '',                
            text: ``
        },
        status: null,
        show: false
    });

    const updateToast = ({
        title,
        status,
        content: {
            name,
            text
        },
        show
    }) => {
        let toast = toastContent;

        toast.title = title;
        toast.status = status;
        toast.content.name = name;
        toast.content.text = text;
        toast.show = show;
        
        setToastContent({
            ...toast
        });
    }

    const closeToast = () => {
        let toast = toastContent;

        toast.show = false;

        updateToast(toast);
    };

    const openMobileSidebar = () => {
        setShowMobileSidebar(true);
    }

    const closeMobileSidebar = () => {
        setShowMobileSidebar(false);
    }

    return !mobileScreen ? (
        <div className="top-menu-bar">
            <CustomToast show={toastContent.show} 
                    onClose={closeToast} 
                    title={toastContent.title} 
                    status={toastContent.status}
                    customClass="remove-cart-toast"
            >
                <div className="content">
                    <span className='highlighted'>
                        { toastContent.content.name }
                    </span>
                    { toastContent.content.text }
                </div>
            </CustomToast>  
                <NavDropdown className='top-menu-item' title={<FontAwesomeIcon icon={['fas', 'user']} />} id="auth-dropdown">
                    {
                        customer.hasOwnProperty('id') ? (
                            <>
                                <Link className="dropdown-item" to="/profile/account">
                                    <FontAwesomeIcon className='category-item-icon' icon={['fas', 'user']} /> Detail Akun
                                </Link>
                                <Link className="dropdown-item" to="/profile/address">
                                    <FontAwesomeIcon className='category-item-icon' icon={['fas', 'map-marker-alt']} /> Alamat Saya
                                </Link>
                                <Link className="dropdown-item" to="/profile/order">
                                    <FontAwesomeIcon className='category-item-icon' icon={['fas', 'box']} /> Pesanan Saya
                                </Link>
                                <NavDropdown.Divider />
                                <button className="dropdown-item" onClick={() => logout()}>
                                    <FontAwesomeIcon className='category-item-icon' icon={['fas', 'sign-out-alt']} /> Logout
                                </button>
                            </>
                        ) : (
                            <Link className='dropdown-item' to="/login" reloadDocument={true}>
                                <FontAwesomeIcon className='category-item-icon' icon={['fas', 'sign-in-alt']} /> Login
                            </Link>
                        )
                    }
                </NavDropdown>
                {
                    customer.hasOwnProperty('id') && (
                        <span className='top-menu-item username'>
                            Hello, { customer.username }
                        </span>
                    )
                }
                {
                    allowTransaction ? (
                        <>
                            <ShoppingCart show={show} cartCount={cartCounter} onClose={ () => closeShoppingCart() }/>
                            <button className="top-menu-item" onClick={ () => openShoppingCart() }>
                                <FontAwesomeIcon icon={['fas', 'shopping-cart']}  />
                                {
                                    cartCounter > 0 ? (
                                        <span className="cart-counter">
                                            { cartCounter }
                                        </span>
                                    ) : (
                                        ''
                                    )
                                }
                            </button>
                        </>
                    ) : ('')
                }
            
            <NavDropdown className='top-menu-item' title={<FontAwesomeIcon icon={['fas', 'bars']}  />} id="info-dropdown">
                {/* <Link className='dropdown-item' to="/payment-confirmation">
                    <FontAwesomeIcon className='category-item-icon' icon={['fas', 'check-circle']} /> Konfirmasi Pembayaran
                </Link> */}
                
                <a className='dropdown-item' href={ "#packaging-catalog" }>
                    Produk Kami
                </a>
                <a className='dropdown-item' href={ "#order-instruction" }>
                    Cara Order
                </a>
                <a rel="noreferrer" className='dropdown-item' href={waLink} target='_blank'>
                    <FontAwesomeIcon className='category-item-icon' icon={['fas', 'phone']} /> Hubungi Kami
                </a>
                <Link className='dropdown-item' to="/terms-and-condition">
                    <FontAwesomeIcon className='category-item-icon' icon={['fas', 'question-circle']} /> Terms & Conditions
                </Link>
                <Link className='dropdown-item' to="/privacy-policy">
                    <FontAwesomeIcon className='category-item-icon' icon={['fas', 'exclamation-circle']} /> Privacy Policy
                </Link>
                <Link className='dropdown-item' to="/return-policy">
                    <FontAwesomeIcon className='category-item-icon' icon={['fas', 'exchange']} /> Return Policy
                </Link>
            </NavDropdown>
        </div>
    ) : (
        <div className='top-menu-bar'>
            <MobileSidebar show={showMobileSidebar} menu={menu} onClose={ () => closeMobileSidebar() } />
            <button className="top-menu-item" onClick={ () => openMobileSidebar() }>
                <FontAwesomeIcon icon={['fas', 'bars']}  />
            </button>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);