import addonsAPI from '../../Data/Addons';
import * as actionTypes from './addon-types';

const getAddons = () => {
    return async dispatch => {
        const response = await addonsAPI.addonsList();
    
        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            dispatch(getAddonsSuccess(response.data)); 
        }
    }
}

const getAddonsSuccess = (data) => {
    return {
        type: actionTypes.ADDON_LIST,
        payload: {
            data
        }
    };
}

const addonActions = {
    getAddons
}

export default addonActions;