import React, { useState, useEffect, useRef, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import FormInput from '../../../Utilities/FormInput/FormInput';
import PrimaryButton from '../../../Utilities/PrimaryButton/PrimaryButton';

import NavigationContext from '../../../../Context/NavigationContext';

import './OTPForm.scss';
import ContactInfo from '../../../../Data/Contact';

import UtilityFunction from '../../../../Util/Util';
import authAPI from '../../../../Data/Auth';
import { useNavigate } from 'react-router-dom';
import CustomToast from '../../../Utilities/Toast/Toast';
import SecondaryButton from '../../../Utilities/SecondaryButton/SecondaryButton';

const { getFormattedDatetime, base64encode, responseFormat } = UtilityFunction;

function OTPForm(props) {
    const navigate = useNavigate();
    const [tick, setTick] = useState(0);
    const intervalRef = useRef();
    const timeLimit = 60; // Seconds

    const otpFormData = {
        otp: {
            label: "OTP",
            ref: useRef(''),
            value: '',
            width: 8, // x < 12
            type: "text",
            required: true,
            // numeric: true,
            placeholder: 'Enter OTP'
        },
    }

    const [formData, setFormData] = useState(otpFormData);
    const navigation = useContext(NavigationContext);
    
    const [toastContent, setToastContent] = useState({
        title: '',
        content: '',
        status: null,
        show: false
    });

    const closeToast = () => {
        setToastContent({
            ...toastContent,
            show: false
        });
    }

    const openToast = (isSuccess, message) => {
        const content = toastContent;

        content.title = isSuccess ? 'Success' : 'Failed';
        content.content = message;
        content.status = isSuccess;
        content.show = true;

        setToastContent({
            ...content
        });
    }

    // useEffect( () => {
        
    // }, []);
    const cooldown = () => {
        setTick(timeLimit);

        intervalRef.current = setInterval(() => {
            setTick( (prev) => {
                if( prev > 0 ) {
                    prev -= 1;
                } else {
                    clearInterval(intervalRef.current);
                    prev = 0;
                }

                return prev;
            } );
        }, 1000);
    }

    const resendOTP = () => {
        (
            async() => {
                const response = await authAPI.otpGenerate(props.customerCode);

                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    cooldown();
                } else {
                    for(let index in response.data) {
                        let item = response.data[index];
                        let field = item.hasOwnProperty('field') ? item.field : '';
                        let message = item.hasOwnProperty('message') ? item.message : '';

                        if(field !== '' && formData.hasOwnProperty(field)) {
                            formData[field].hasError = true;
                            formData[field].errorMessage = message;
                        }
                    }

                    setFormData({
                        ...formData
                    });
                }

                openToast(
                    response.hasOwnProperty('errorCode') && response.errorCode === 0,
                    response.message
                );
            }
        )();
    };

    const verifyOTP = () => {
        const otp = formData.otp.ref.current.value;

        (
            async() => {
                const response = await authAPI.otpVerify(props.customerCode, otp);
                console.log(response);

                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    // navigate(`/?token=${ response.data.token }`);
                    window.location.href = `/?token=${ response.data.token }`;
                } else {
                    for(let index in response.data) {
                        let item = response.data[index];
                        let field = item.hasOwnProperty('field') ? item.field : '';
                        let message = item.hasOwnProperty('message') ? item.message : '';

                        if(field !== '' && formData.hasOwnProperty(field)) {
                            formData[field].hasError = true;
                            formData[field].errorMessage = message;
                        }
                    }

                    setFormData({
                        ...formData
                    });
                }

                openToast(
                    response.hasOwnProperty('errorCode') && response.errorCode === 0,
                    response.message
                );
            }
        )();
    };

    return (
        <>
            <CustomToast show={toastContent.show} 
                onClose={closeToast} 
                title={toastContent.title} 
                status={toastContent.status}
                customClass="account-modify-toast"
            >
                <div className="content">
                    { toastContent.content }
                </div>
            </CustomToast>
            <div className='otp-form-container'>
                <Row className={`otp-form`}>
                    {
                        Object.keys(formData).map( (key, index) => {
                            let item = formData[key];

                            return <FormInput 
                                        key={index} 
                                        attribute={item}
                                        options={item.hasOwnProperty('options') ? item.options : []}
                                        name={key} 
                                        // onChange={ (name, value) => formUpdate(name, value) } 
                                    />
                        } )
                    }
                </Row>
                <Row>
                    <Col sm={4}>
                        <SecondaryButton size="sm" customClass="btn-otp-form" disabled={ tick > 0 } onClick={() => resendOTP()}>
                            Resend { tick ? `${ tick }s` : "" }
                        </SecondaryButton>
                    </Col>
                    <Col sm={4}>
                        <PrimaryButton size="sm" customClass="btn-otp-form" onClick={() => verifyOTP()}>
                            Submit
                        </PrimaryButton>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default OTPForm;