import React from "react";

import Init from '../../../../Init/Init';
import UtilityFunction from '../../../../Util/Util';

import Counter from '../../../Utilities/Counter/Counter';
import PrimaryButton from '../../../Utilities/PrimaryButton/PrimaryButton';

import "./CheckoutBox.scss";
import SecondaryButton from "../../../Utilities/SecondaryButton/SecondaryButton";

const { numberFormatting, buildWaLink } = UtilityFunction;

const CheckoutBox = (props) => {
    const { config } = Init;

    const {
        productImage,
        productName,
        variantName,
        changeCounter,
        selectedVariant,
        quantity,
        moq,
        directToWa,
        totalPrice,
        pricePerUnit,
        addToCartDisabled,
        addToCart,
        addon,
        useMultiColor = 0
    } = props;

    const addItem = () => {
        if( !addToCartDisabled ) addToCart();
    }

    const contactWhatsapp = () => {
        window.open(buildWaLink(false, `Halo Yoboycup, saya ingin memesan ${ `${productName} ${variantName} ${ addon !== "" ? `dengan ${addon}` : "" }` } sebanyak ${ quantity } pcs.`), '_blank');
    }

    return (
        <div className="checkout-box">
            {/* 
                Flex Row =>
                    1. Image
                    2. Product (Name, Variant)
                    3. Qty Counter
                    4. Total Price (Label, Value;Price Per Unit)
                    5. Add to Cart Button
            */}
            <div className="checkout-product">
                <div className="checkout-product-first-section">
                    <div className="checkout-product-image">
                        <img src={ productImage } alt="" />
                    </div>
                    <div className="checkout-product-info">
                        <div className="checkout-product-info-name">
                            { productName }
                        </div>
                        <div className="checkout-product-info-variant">
                            { `${variantName} ${ addon !== "" ? `+ ${addon}` : "" }` }
                        </div>
                    </div>
                </div>
                <div className="checkout-product-second-section">
                    <div className="checkout-product-qty">
                        <span className="checkout-product-qty-label">
                            Jumlah:
                        </span>
                        <Counter 
                            size="sm" 
                            onModify={(counter) => changeCounter(counter)} 
                            disabled={!selectedVariant} 
                            baseValue={quantity} 
                            useMultiColor={useMultiColor}
                            minValue={moq}
                            maxValue={config.orderRule.maxQty}
                            customClass="checkout-product-qty-input-container" 
                        />
                    </div>

                    <div className="checkout-product-pricing">
                        <div className="checkout-product-pricing-label">
                            Total Harga
                        </div>
                        <div className="checkout-product-pricing-value">
                            <span className="price">
                                { numberFormatting(totalPrice) }
                            </span>
                            <span className="price-per-unit">
                                { numberFormatting(pricePerUnit) }/pcs
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="checkout-button-container">
                {/* Button Add To Cart */}
                {
                    directToWa ? (
                        <SecondaryButton size='md' customClass={`btn-add-to-cart`} disabled={ addToCartDisabled } onClick={ () => contactWhatsapp() }>
                            Pesan via Whatsapp Boy!
                        </SecondaryButton>
                    ) : (
                        <SecondaryButton size="md" customClass={`btn-add-to-cart`} disabled={ addToCartDisabled } onClick={ () => addItem() }>
                            Masuk Keranjang
                        </SecondaryButton>
                    )
                }
            </div>
        </div>
    );
}

export default CheckoutBox;