const TermsAndCondition = () => {
    return {
        header: {
            title: "Terms and Conditions",
            description: [
                `𝗣𝗧. 𝗬𝗢𝗕𝗢𝗬 𝗢𝗦𝗪𝗔𝗟𝗗 𝗜𝗠𝗔𝗝𝗜 (“YOBOY CUP”)’s Site (“<a target="_blank" href="https://yoboycup.com" class="text-link">www.yoboycup.com</a>”) is offered to you, the user, conditioned on your acceptance of the terms, conditions and notices contained or incorporated by reference herein and such additional terms and conditions, agreements, and notices that may apply to any page or section of the Site (“Terms of Use”).`
            ]
        },
        list: [
            {
                title: "Description of Services",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            "The site is a marketplace where you can custom cup packaging starting from just 250 pcs. And we want to make it easy for you Food and Beverage business people wherever you are, we are ready to help provide the best quality printouts and can be sent throughout Indonesia.",
                        ]
                    },
                ]
            },
            {
                title: "Overview",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            "Your use of this Site constitutes your agreement to all terms, conditions and notices. Please read them carefully. By using this Site, you agree to these Terms of Use, as well as any other terms, guidelines or rules that are applicable to any portion of this Site, without limitation or qualification. If you do not agree to these Terms of Use, you must exit the Site immediately and discontinue any use of information or products from this Site."
                        ]
                    },
                ]
            },
            {
                title: "Image Terms of Use",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            "If you think we have published an image or text that violates your copyright, we will address your concerns; however, if the image falls into one of the listed categories above and we believe that our use of the image is legitimate, we will not remove it from the Site. All the use of our image from our site is prohibited, we have the right to sue any image taken from our site without our consent."
                        ]
                    },
                ]
            },
            {
                title: "Data Integrity",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            `All of the information, data and other materials on Site provides are true, accurate, current and complete. YOBOY CUP is responsible for updating and correcting the information we have provided by updating the information on the account we may establish on <a target="_blank" href="https://yoboycup.com" class="text-link">www.yoboycup.com</a>`
                        ]
                    },
                ]
            },
            {
                title: "Privacy Policy",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            `A copy of the Privacy Policy that applies to the collection, use, disclosure and other processing of personal information by Yoboy Cup is located at <a target="_blank" href="https://yoboycup.com/privacy-policy" class="text-link">www.yoboycup.com/privacy-policy</a>. You consent to any personal information we may obtain about you (either via Site, by email, telephone or any other means) being collected, stored and otherwise processed in accordance with the terms of the Privacy Policy. YOBOY CUP may update its Privacy Policy from time to time, in its sole discretion, and post an updated version of the notice at the <a target="_blank" href="https://yoboycup.com" class="text-link">www.yoboycup.com</a>`
                        ]
                    },
                ]
            },
            {
                title: "Changes to Privacy Policy",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            "We may occasionally update the Site’s Privacy Policy as it is necessary to protect our customers and to comply with a changing environment. We recommend that you check the Site’s Privacy Policy when you visit to be sure that you are aware of and understand our current policy. If we make material changes to this policy or in how we will use or collect your personally identifiable information, we will prominently post such changes prior to implementing the change. We will not make material changes in how we handle previously collected personal information that have retroactive effect unless legally required to do so, and we will e-mail registered Site visitors information on material changes to this policy. Your continued use the Site means you accept those changes for all information we have provided to you."
                        ]
                    },
                ]
            },
            {
                title: "Sign Up",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            `You don’t need to sign up to purchase (later will be called as “YOBOY CUP account”), but you can get many benefit and update if you create account in our site (<a target="_blank" href="https://yoboycup.com" class="text-link">www.yoboycup.com</a>).`
                        ]
                    },
                ]
            },
            {
                title: "Payment",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            "We use Rupiah (IDR), the currency of Indonesia, for any payment and/or charge onto your credit card. Prices shown in the Site may be displayed in a foreign currency but we will use IDR as the transaction price charged instead based on the conversion rate of such foreign currency into IDR applicable in our bank. Please note, items will not be shipped until the transaction has been cleared."
                        ]
                    }
                ]
            },
            {
                title: "Modification of the Site and Terms of Use",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            "YOBOY CUP has the right to change, modify, alter, update or discontinue the terms, conditions, and notices under which this Site is offered and the links, content, information, prices and any other materials offered via this Site at any time and from time to time without notice or further obligation to you except as may be provided therein. We have the right to adjust prices from time to time. If for some reason there may have been a price mistake, Site has the right to refuse the order. By your continued use of the Site, you agree to be bound by such modifications, alterations, or updates."
                        ]
                    }
                ]
            },
            {
                title: "Copyright",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            "All content and compilations included on this Site, such as products, graphics, logos, images, data, and photographs (“Contents”) is the property of YOBOY CUP.",
                            "YOBOY CUP is the legitimate owner of all rights of the Site and the content of the Site. This Site and all of its content shall include the intellectual property rights preserved by the law and regulations.",
                            "All of the Materials appearing on this Site, including but not limited to, text elements, site design, images and icons, as well as the selection, assembly and arrangement thereof, are the sole property of YOBOY CUP, unless otherwise specified. All rights not expressly granted herein are reserved. Except as otherwise required by applicable law, any reproduction, distribution, modification, retransmission or publication of any copyrighted material is strictly prohibited without the express written consent of the copyright owner."
                        ]
                    }
                ]
            },
            {
                title: "Commercial Use",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            "You may not copy, reproduce, sell any contents of this Site for any commercial use for your own Site.",
                        ]
                    }
                ]
            },
            {
                title: "Communications",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            "You agree that Site may send electronic mails to you for the purpose of advising you of changes or additions to this Site, about any of our products or services, or for such other purpose(s) as we deem appropriate. If you wish to unsubscribe from our newsletters, please click on “Newsletters” in your account page and unsubscribe.",
                        ]
                    }
                ]
            },
            {
                title: "Stock Availability & Price Changes",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            "YOBOY CUP ensures that all our product descriptions and prices are up-to-date and as accurate as possible. However, in few cases, pricing errors may occur with our listings.",
                            "We cannot guarantee that your monitor’s display of any color will be accurate as the actual colors you see depends on your monitor quality.",
                            "Should we discover a pricing error while processing your order and payment, we will immediately rectify the problem before cancelling and refunding you for your order. We will also make sure to inform you about the error with your order as soon as possible."
                        ]
                    }
                ]
            },
            {
                title: "Cost of Shipping",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            "Shipping fees are free since delivery methods are chosen by our team."
                        ]
                    }
                ] 
            },
            {
                title: "Risk of Loss",
                description: [
                    {
                        type: "paragraph",
                        content: [
                            "All items purchased from Yoboy Cup are made pursuant to a Delivery information. This means that the risk of loss and title for such items pass to you upon our delivery to the carrier."
                        ]
                    }
                ]
            },
            {
                title: "Warranty Disclaimer",
                description: [
                    {
                        type: 'paragraph',
                        content: [
                            `We provide this Site on an "as is" and "as available" basis. You therefore use the Site at your own risk. WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND ANY OTHER WARRANTY THAT MIGHT ARISE UNDER ANY LAW.`,
                            `Without limiting the foregoing, we make no representation or warranties (i) that the Site will be permitted in your jurisdiction; (ii) that the Site will be uninterrupted or error-free; (iii) concerning any third party's use of user content that you submit; or (iv) concerning any user content submitted by any other user.`
                        ]
                    }
                ]
            },
            {
                title: "Limitation of Liability",
                description: [
                    {
                        type: 'paragraph',
                        content: [
                            `TO THE FULLEST EXTENT PERMITTED BY THE LAW: IN NO EVENT SHALL WE, OUR OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR SERVICES, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR SERVICES, (V) ANY BUGS, VIRUSES TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH OUR SERVICES BY ANY THIRD PARTY, AND/OR (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA OUR SITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.`,
                            `YOU SPECIFICALLY ACKNOWLEDGE THAT WE DO NOT ACTIVELY SCREEN BLOG COMMENTS AND FORUM POSTS BEFORE THEY ARE UPLOADED TO OUR SITE BY THIRD PARTIES AND, WHETHER OR NOT WE WOULD OTHERWISE NORMALLY BE HELD LIABLE FOR SUCH WORDS UNDER THE LAWS OF INDONESIA OR ANY OTHER JURISDICTION, WE SHALL NOT BE LIABLE FOR THE CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.`,
                            `THE SITE IS OFFERED BY US FROM OUR FACILITIES IN INDONESIA WE MAKE NO REPRESENTATIONS THAT THE SITE IS APPROPRIATE OR AVAILABLE FOR USE IN OTHER LOCATIONS. THOSE WHO ACCESS OR USE THE SITE FROM OTHER JURISDICTIONS DO SO AT THEIR OWN VOLITION AND ARE RESPONSIBLE FOR COMPLIANCE WITH LOCAL LAW.`
                        ]
                    }
                ]
            }
        ]
    }
}

export default TermsAndCondition;