import React, { Component } from 'react';
import Init from '../../../Init/Init';
import './Footer.scss';

import ContactInfo from '../../../Data/Contact';
import UtilityFunction from '../../../Util/Util';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const { buildWaLink } = UtilityFunction;

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footerInfo: ContactInfo(),
            waLink: buildWaLink()
        };

        this.config = Init.config;
    }

    static getDerivedStateFromProps(props, state) {
        let identity = {};

        if(props.hasOwnProperty('identity') && typeof props.identity === 'object') {
            identity = props.identity;
        } 

        return {
            ...state,
            identity
        }
    }

    render() {
        let state = this.state;
        let config = this.config;
        let footerInfo = state.footerInfo;
        let FontAwesomeIcon = Init.FontAwesomeIcon;

        const { imageLogoAlt } = config;

        return (
            <footer id="contact" className={
                "footer-contact" 
                + 
                (this.props.hide ? ' d-none' : '')
            }>
                <h3 className="footer-contact-title">
                    Media Sosial
                </h3>
                {/* Socmed */}
                <div className="footer-contact-socmed">
                    {
                        footerInfo.connect.map( (item, index) => {
                            return (
                                <a key={index} href={ item.link } target="_blank" className="socmed-item">
                                    {
                                        item.faIcon !== null ? (
                                            <FontAwesomeIcon icon={item.faIcon} />
                                        ) : <img src={ item.customIcon } />
                                    }
                                </a>
                            )
                        } )
                    }
                </div>
                {/* Copyright */}
                <div className="footer-copyright">
                    @Copyright 2022 - PT. YOBOY OSWALD IMAJI, yoboycup.com
                </div>
                <div className="footer-menu">
                    <a target="_blank" href="/terms-and-condition" className="footer-menu-link">
                        Terms and Conditions
                    </a>
                    <div className="footer-menu-separator">
                        |
                    </div>
                    <a target="_blank" href="/privacy-policy" className="footer-menu-link">
                        Privacy Policy
                    </a>
                    <div className="footer-menu-separator">
                        |
                    </div>
                    <a target="_blank" href="/return-policy" className="footer-menu-link">
                        Return & Refund Policy
                    </a>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        identity: state.auth.identity
    }
}

export default connect(mapStateToProps)(Footer);