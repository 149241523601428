import shippingAPI from '../../Data/Shipping';
import * as actionTypes from './shipping-types';

const getCourier = () => {
    return async dispatch => {
        const response = await shippingAPI.courierList();
    
        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            dispatch(getCourierSuccess(response.data)); 
        }
    }
}

const getCourierSuccess = (data) => {
    return {
        type: actionTypes.COURIER_LIST,
        payload: {
            data
        }
    };
}

const shippingActions = {
    getCourier
}

export default shippingActions;